<template>
    <div class="d-flex flex-wrap flex-stack my-5 justify-content-end">
        <el-tooltip :content="$t('common.exportTooltip')" popper-class="max-w-300px" placement="bottom">
            <button class="btn btn-danger align-self-center" :data-kt-indicator="exportLoading ? 'on' : null" :disabled="exportLoading" v-on:click="exportUser">
                <span v-if="!exportLoading" class="indicator-label">
                    <span class="svg-icon svg-icon-1">
                        <inline-svg src="/media/icons/duotune/files/fil021.svg" />
                    </span>
                    {{ $t("common.export") }}
                </span>
                <span v-if="exportLoading" class="indicator-progress">
                    <span class="spinner-border spinner-border-sm align-middle me-2"></span>
                    {{ $t("messages.wait") }}
                </span>
            </button>
        </el-tooltip>
    </div>
    <custom-table
        :title="$t('pages.ecommerce.user.title')"
        :subTitle="$t('pages.ecommerce.user.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        changeUrlPage
        filterComponentName="EcommerceUserFilter"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:name="{ row: record }">
            {{ record.firstname + ' ' + record.lastname }}
        </template>
        <template v-slot:user="{ row: record }">
            <span class="fw-bolder">{{ record.firstname + ' ' + record.lastname }}</span><br>
            <span class="fw-bolder fs-7">{{ $t('pages.ecommerce.user.cols.identificationNumberShort') }}: </span>
            <span class="fs-7">{{ (record.identification_number ?? "-") }}</span>
        </template>
        <template v-slot:mobilePhone="{ row: record }">
            <a :href="'tel:' + record.mobile_phone" class="text-gray-600 text-hover-primary mb-1" v-if="record.mobile_phone">
                {{ record.mobile_phone }}
            </a>
            <span v-else>-</span>
        </template>
        <template v-slot:email="{ row: record }">
            <a :href="'mailto:' + record.email" class="text-gray-600 text-hover-primary mb-1" v-if="record.email">
                {{ record.email }}
            </a>
            <span v-else>-</span>
        </template>
        <template v-slot:gender="{ row: record }">
            {{ record.gender ? record.gender.name : '-' }}
        </template>
        <template v-slot:is_abroad="{ row: record }">
            <span :class="record.is_abroad ? 'badge-light-primary': 'badge-light'" class="badge">{{ record.is_abroad ? $t('common.yes') : $t('common.no') }}</span>
        </template>
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <!--<a v-on:click="fetchUser(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>-->
                <router-link :to="'/ecommerce/user/save/' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                     <span class="svg-icon svg-icon-3">
                         <inline-svg src="/media/icons/duotune/art/art005.svg" />
                     </span>
                </router-link>
                <el-tooltip :content="$t('pages.ecommerce.user.address.title')" placement="top">
                    <router-link :to="'/ecommerce/user/address/' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/maps/map008.svg"/>
                        </span>
                    </router-link>
                </el-tooltip>
                <el-tooltip :content="$t('pages.ecommerce.user.createOrder')" placement="top">
                    <router-link :to="'/ecommerce/order-management/order/save?userID=' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/ecommerce/ecm001.svg"/>
                        </span>
                    </router-link>
                </el-tooltip>
                <el-tooltip :content="$t('pages.ecommerce.user.bougthProducts')" placement="top">
                    <router-link :to="'/ecommerce/order-management/order?userID=' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/general/gen031.svg"/>
                        </span>
                    </router-link>
                </el-tooltip>
                <el-tooltip :content="$t('pages.ecommerce.user.examResult')" placement="top">
                    <router-link :to="'/module/exam-management/exam/result?userID=' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/general/gen005.svg" />
                        </span>
                    </router-link>
                </el-tooltip>
                <el-tooltip :content="$t('pages.ecommerce.user.changePassword')" placement="top">
                    <a v-on:click="changePassword(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/general/gen047.svg"/>
                        </span>
                    </a>
                </el-tooltip>
                <el-tooltip :content="$t('pages.ecommerce.user.selfLogin')" placement="top">
                    <a v-on:click="selfLogin(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr076.svg"/>
                        </span>
                    </a>
                </el-tooltip>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_user" ref="addUserModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="userForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.firstname') }}</label>
                                <el-form-item prop="firstname" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.firstname" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.lastname') }}</label>
                                <el-form-item prop="lastname" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.lastname" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.email') }}</label>
                                <el-form-item prop="email" :rules="$validation.getMessage(['requiredBlur', 'email'])">
                                    <el-input v-model="form.data.email" autocomplete="off"/>
                                </el-form-item>
                            </div>

                            <template v-if="!form.updateStatus">
                                <div class="fv-row mb-7">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.password') }}</label>
                                    <el-form-item prop="password" :rules="rules.password">
                                        <el-input v-model="form.data.password" autocomplete="off" type="password"/>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-7">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.passwordConfirmation') }}</label>
                                    <el-form-item prop="password_confirmation" :rules="rules.password_confirmation">
                                        <el-input v-model="form.data.password_confirmation" autocomplete="off" type="password"/>
                                    </el-form-item>
                                </div>
                            </template>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.gender') }}</label>
                                <el-form-item prop="gender_id">
                                    <el-select v-model="form.data.gender_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                        <el-option v-for="(gender, genderIndex) in genders" :key="genderIndex" :value="gender.id" :label="gender.name">{{ gender.name }}</el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.identificationNumber') }}</label>
                                <el-form-item prop="identification_number">
                                    <el-input v-model="form.data.identification_number" v-mask="{ mask:'99999999999', placeholder: '' }" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.birthDate') }}</label>
                                <el-form-item prop="birth_date">
                                    <el-date-picker v-model="form.data.birth_date" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledDate"></el-date-picker>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.mobilePhone') }}</label>
                                <el-form-item prop="mobile_phone">
                                    <el-input v-model="form.data.mobile_phone" v-mask="{ mask:'+999999999999', placeholder: '' }" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.isAbroad') }}</label>
                                <el-form-item prop="status">
                                    <el-radio-group v-model="form.data.is_abroad">
                                        <el-radio-button :label="true">{{ $t("common.yes") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.no") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.status') }}</label>
                                <el-form-item prop="status">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_change_password" ref="changePasswordModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_change_password_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_change_password_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onChangePasswordSubmit()" :model="form.data" ref="changePasswordForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_change_password_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.password') }}</label>
                                <el-form-item prop="password" :rules="rules.password">
                                    <el-input v-model="form.data.password" autocomplete="off" type="password"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.user.cols.passwordConfirmation') }}</label>
                                <el-form-item prop="password_confirmation" :rules="rules.password_confirmation">
                                    <el-input v-model="form.data.password_confirmation" autocomplete="off" type="password"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "user",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.ecommerce.user.cols.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.ecommerce.user.cols.user"),
                    scopedSlots: {customRender: "user"},
                },
                {
                    name: this.$t("pages.ecommerce.user.cols.mobilePhone"),
                    scopedSlots: {customRender: "mobilePhone"}
                },
                {
                    name: this.$t("pages.ecommerce.user.cols.email"),
                    scopedSlots: {customRender: "email"},
                },
                {
                    name: this.$t("pages.ecommerce.user.cols.order"),
                    key: "orders_count"
                },
                {
                    name: this.$t("pages.ecommerce.user.cols.gender"),
                    scopedSlots: {customRender: "gender"}
                },
                {
                    name: this.$t("pages.ecommerce.user.cols.isAbroad"),
                    scopedSlots: {customRender: "is_abroad"}
                },
                {
                    name: this.$t("pages.ecommerce.user.cols.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                currentForm: 'userForm',
                data: {
                    password: '',
                    password_confirmation: '',
                }
            },
            rules: {},
            selectedRowKeys: [],
            filterParameters: {},
            exportLoading: false
        }
    },
    created() {
        this.rules = {
            password: [{validator: this.validatePass, trigger: ['blur']}],
            password_confirmation: [{validator: this.validatePass2, trigger: ['blur', 'change']}],
        }
    },
    computed: {
        table() {
            return this.$store.state.ecommerce.user.table;
        },
        genders() {
            return this.$store.state.ecommerce.user.gender.table.data;
        },
        userID(){
            return this.$route.query.userID;
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("menu.users"), [this.$t("menu.ecommerceManagement"), this.$t("menu.userManagement")]);

        this.filterParameters = {
            id: this.userID,
            sort: 'created_at:desc'
        };

        this.$store.dispatch('ecommerce/user/get', {
            page: {
                current: this.$route.query.page
            },
            filterData: Object.assign(this.filterParameters, this.$root.getFilterWithUrl({}))
        });

        this.$store.dispatch('ecommerce/user/gender/get', {page: {}});
    },
    methods: {
        newUser() {
            this.form.updateStatus = false;
            this.form.currentForm = 'userForm';
            this.form.data = {
                password: '',
                password_confirmation: '',
                is_abroad: false,
                active: true
            };
            this.form.title = this.$t("pages.ecommerce.user.newUser");
            this.showModal(this.$refs.addUserModal);
        },
        fetchUser(record) {
            this.form.updateStatus = true;
            this.form.currentForm = 'userForm';
            this.form.title = this.$t("pages.ecommerce.user.editUser")
            this.axios.get(this.endpoints['user'] + '/' + record.id).then(response => {
                this.form.data = response.data.data;
                this.showModal(this.$refs.addUserModal);
            });
        },
        changePassword(record) {
            this.form.currentForm = 'changePasswordForm';
            this.form.title = this.$t("pages.ecommerce.user.changePassword");

            this.form.data = {
                id: record.id,
                password: '',
                password_confirmation: ''
            };

            this.showModal(this.$refs.changePasswordModal);
        },
        selfLogin(record) {
            this.axios.post(this.endpoints['user_self_login'], {user_id: record.id}).then(response => {
                this.onResponse(response.data, () => {
                    const form = document.createElement('form');

                    form.method = 'POST';
                    form.action = this.$root.appUrl + '/user/self-login';
                    form.target = '_blank';

                    const userId = document.createElement('input');
                    userId.type = 'hidden';
                    userId.name = 'user_id';
                    userId.value = response.data.data.user.id;
                    form.appendChild(userId);

                    const userToken = document.createElement('input');
                    userToken.type = 'hidden';
                    userToken.name = 'user_token';
                    userToken.value = response.data.data.token.accessToken;
                    form.appendChild(userToken);

                    document.body.appendChild(form);
                    form.submit();
                }, undefined, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            });
        },
        onSubmit() {
            this.$refs.userForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['user'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.addUserModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        this.axios.post(this.endpoints['user'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.addUserModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        onChangePasswordSubmit() {
            this.$refs.changePasswordForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['user_change_password'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.hideModal(this.$refs.changePasswordModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id) {
            this.$store.dispatch("ecommerce/user/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    //this.newUser();
                    this.$router.push({
                        path: "/ecommerce/user/save"
                    });
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("ecommerce/user/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.filterParameters = Object.assign({
                sort: 'created_at:desc',
            }, filterData);

            this.$store.dispatch("ecommerce/user/get", {
                page: pagination,
                filterData: this.filterParameters
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
        disabledDate(date) {
            return date.getTime() > Date.now()
        },
        validatePass(rule, value, callback) {
            if (value === '') {
                callback(new Error(this.$t('validation.password')))
            } else {
                if (this.form.data.password_confirmation !== '') {
                    this.$refs[this.form.currentForm].validateField('password_confirmation')
                }
                callback()
            }
        },
        validatePass2(rule, value, callback) {
            if (value === '') {
                callback(new Error(this.$t('validation.passwordConfirmation')))
            } else if (value !== this.form.data.password) {
                callback(new Error(this.$t('validation.dontMatchPassword')))
            } else {
                callback()
            }
        },
        exportUser(){
            this.exportLoading = true;
            this.axios({
                url: this.endpoints['user_export'],
                method: 'get',
                responseType: "blob",
                params: this.filterParameters
            }).then(response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.click();
            }).finally(() => {
                this.exportLoading = false;
            });
        }
    }
}
</script>

<style scoped>

</style>